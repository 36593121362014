import toast from "react-hot-toast";
// import useForm from "../hooks/useForm";
import AddandEditform from "../shared/components/Gallery/AddandEditform";
import { CustomTable } from "../shared/components/Gallery/CustomTable";

import { apideleteGallery, apigetallGallery, apisaveGallery, apiupdateGallery } from "../shared/services/apiGallery/apigallery";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useTable from "../hooks/useTable";
import Cuspagination from "../shared/components/Courses/Cuspagination";
import { useState } from "react";
import TableheadPanel from "../shared/components/Gallery/TableheadPanel";


export default function GalleryPage (initialValues) {

    // const {  visible, setVisible, openform, editform,resetForm,formdata,setformdata } = useForm({});
    const [visible, setVisible] = useState(false);
    const [formdata,setformdata]=useState(initialValues);
    
    const openform=()=>{setformdata({});setVisible(true)}
    const editform=(data)=>{setformdata({}); setformdata(data); setVisible(true);}
    const resetForm = () => { setformdata(initialValues); setVisible(false);}
    
    const { page, first, rows, globalFilter, onPage } = useTable();

     const queryClient = useQueryClient();
     const { data } = useQuery({ queryKey: ['courseData', page, rows, globalFilter], queryFn: () => apigetallGallery({ first, rows, globalFilter }) })

     const handlechange = (e) => {
        const { name, value, type, files } = e.target;
        
        setformdata(prev => ({...prev,[name]: type === 'file' ? (files.length > 1 ? Array.from(files) : files[0]) : value})); };

    const handlesave = async (formdata) => {
        try {
            for (let [key, value] of formdata.entries()) {
                console.log(`${key}:`, value);
            }
    
            const response = await apisaveGallery(formdata);
            console.log("Gallery saved successfully:", response);
            toast.success("Gallery saved successfully")
            queryClient.invalidateQueries('courseData');
            setVisible(false);
        } catch (error) {
            console.error("Error saving gallery:", error);
        }
    };

    const handleupdate = async (formdata) => {
        try {
            // Ensure formdata is a FormData object
            if (!(formdata instanceof FormData)) {
                formdata = new FormData();
                
                // Add existing fields
                for (const key in formdata) {
                    if (key !== 'images') {
                        formdata.append(key, formdata[key]);
                    }
                }
    
                // Add images specifically
                if (formdata.images) {
                    if (Array.isArray(formdata.images)) {
                        formdata.images.forEach((image) => {
                            formdata.append('images', image);
                        });
                    } else {
                        formdata.append('images', formdata.images);
                    }
                }
            }
    
            const response = await apiupdateGallery(formdata);
            toast.success("Gallery updated successfully");
            queryClient.invalidateQueries('courseData');
            setVisible(false);
        } catch (error) {
            console.error("Error updating gallery:", error);
            toast.error("Failed to update gallery");
        }
    };
    

    const handledelete = async (_id) => {
        const res = await apideleteGallery(_id);
        res.message == "Successfully deleted" ? handlesuccess(res) : toast.error(res.message);

    }

    const handlesuccess = (res) => {
        toast.success(res.message)
        queryClient.invalidateQueries('courseData');
        resetForm();
    }

     const confirm2 = (_id) => {
            confirmDialog({
                message: 'Do you want to delete this record?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger bg-red-500 ml-2 text-white py-2 px-3',
                rejectClassName: 'ml-2  py-2 px-3',
                accept: () => { handledelete(_id) }
            });
        };



    return(
        <>
            <TableheadPanel openform={openform}/>
            <AddandEditform visible={visible} setVisible={setVisible} handlesave={handlesave} handlechange={handlechange} formdata={formdata} handleupdate={handleupdate} />
            <CustomTable data={data} editform={editform} confirm2={confirm2} />
            {data?.totallength > 0 && (<Cuspagination page={page} rows={rows} onPage={onPage} totallength={data?.totallength} />)}
            <ConfirmDialog />
        </>
    )
}