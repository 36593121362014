/* eslint-disable react/prop-types */
import { useState, useEffect, useCallback } from 'react';
import { apigetshippingDetails } from '../../services/apicutomers/apicustomers';
import apiurl from '../../services/apiendpoint/apiendpoint';
import { getcartItems } from '../../services/apicart/apicart';
import useAuth from '../../services/store/useAuth';
import useCartStore from "../../services/store/usecart";
import toast from 'react-hot-toast';
import { Edit, Trash2 } from 'lucide-react';
import { apideleteShippingAddress, apiSaveShipping, apiupdateShippingAddress } from '../../services/apishipping/apisippings';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import Addshipping from '../../../shared/components/CheckoutandCart/Addshipping';
import { usePaymentHandlers } from './PaymentFunctions';

export default function CheckoutSidebar({ isOpen, onClose }) {
    
    const [shippingDetails, setShippingDetails] = useState([]);
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const { userdetails } = useAuth();
    const { cart, setCartItems,clearCart } = useCartStore();
    const [visible, setVisible] = useState(false);
    const { initializeRazorpayPayment } = usePaymentHandlers(cart, userdetails, clearCart, setCartItems, onClose);
    const safeCart = Array.isArray(cart) ? cart : [];

    let isMounted = true;

    const fetchShippingDetails = useCallback(async () => {
        try {
            const res = await apigetshippingDetails({ Email: userdetails?.Email });
            setShippingDetails(res.resdata);
        } catch (err) {
            console.log('Error fetching shipping details:', err);
        }
    }, [userdetails?.Email]);

    const fetchCartItems = useCallback(async () => {
        try {
            const response = await getcartItems(userdetails?.Email);
            if (JSON.stringify(cart) !== JSON.stringify(response.response)) {
                setCartItems(response.response);
            }
        } catch (error) {
            console.log('Error fetching cart items:', error);
        }
    }, [userdetails?.Email, cart, setCartItems]);

    useEffect(() => {
        if(isMounted) {
        fetchShippingDetails();
        fetchCartItems();
        }
        return (() => isMounted = false);
    }, [fetchShippingDetails, fetchCartItems]);

    const calculateTotal = () => {
        // Group books by type
        const bookTypeCounts = safeCart.reduce((acc, item) => {
            const bookType = item.productId?.Book_Type || 'Unknown';
            const bookName = item.productId?.Book_Name || '';
            const isComboBook = bookName.includes('Medium Books');
            
            // For combo books, count as 3 books per quantity
            const effectiveQuantity = isComboBook ? item.Quantity * 3 : item.Quantity;
            
            if (!acc[bookType]) {
                acc[bookType] = {
                    regularCount: 0,
                    comboCount: 0
                };
            }
            
            if (isComboBook) {
                acc[bookType].comboCount += item.Quantity;  // Count combo packages
            } else {
                acc[bookType].regularCount += effectiveQuantity;
            }
            
            return acc;
        }, {});
    
        const bookTypeCharges = {
            TNPSC: 0,
            'SRB/DRB (Coop)': 0
        };
    
        // Calculate shipping for each book type
        Object.entries(bookTypeCounts).forEach(([bookType, counts]) => {
            if (bookType === 'TNPSC') {
                const count = counts.regularCount;
                bookTypeCharges.TNPSC = count === 1 ? 100 : (140 + (count - 2) * 40);
            } else if (bookType === 'SRB/DRB (Coop)') {
                // Calculate for regular books
                if (counts.regularCount > 0) {
                    const regularCount = counts.regularCount;
                    bookTypeCharges[bookType] = regularCount === 1 ? 100 : (100 + (regularCount - 1) * 50);
                }
                
                // Calculate for combo books (3 books per combo)
                // Each combo: ₹100 for first book + ₹50 each for other 2 books = ₹200 per combo
                if (counts.comboCount > 0) {
                    // Multiply 200 by the number of combos
                    const comboCharge = counts.comboCount * 200; // 200 = 100 + (2 * 50)
                    bookTypeCharges[bookType] = (bookTypeCharges[bookType] || 0) + comboCharge;
                }
            }
        });
    
        const productTotal = safeCart.reduce((total, item) =>
            total + ((Number(item?.Quantity) || 0) * (Number(item?.productId?.Sale_Price) || 0)), 0
        );
        
        const shippingCharges = Object.values(bookTypeCharges).reduce((a, b) => a + b, 0);
        
        return {
            productTotal,
            bookTypeCharges,
            shippingCharges,
            grandTotal: productTotal + shippingCharges
        };
    };

    const openform = () => {
        setFormData({});
        setVisible(true);
    };

    const handlechange = (e) => e.target.files ? setFormData({ ...formData, [e.target.name]: e.target.files[0] }) : setFormData({ ...formData, [e.target.name]: e.target.value });

    const handleSave = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await apiSaveShipping(formData);
            if (response.message === "Address successfully saved") {
                toast.success(response.message);
                setFormData({});
                setVisible(false);
    
                const updatedDetails = await apigetshippingDetails({ Email: userdetails?.Email });
                setShippingDetails(updatedDetails.resdata);
    
                setSelectedAddressIndex(updatedDetails.resdata.length - 1);
            } else {
                toast.error("Failed to save the address");
            }
        } catch (error) {
            console.log("Error during saving:", error);
            toast.error("Error saving the address");
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        if (shippingDetails.length > 0 && selectedAddressIndex >= shippingDetails.length) {
            setSelectedAddressIndex(0); 
        }
    }, [shippingDetails, selectedAddressIndex]);
    

    const editform = (data) => {
        setFormData({});
        setFormData(data);
        setVisible(true);
    }

    const handleupdate = async (e) => {
        try {
            e.preventDefault();
            const { _id, ...Othersdata } = formData;
            const res = await apiupdateShippingAddress({ _id, Othersdata });
            if (res.message === 'Address updated successfully') {
                toast.success(res.message);
                fetchShippingDetails();
                setVisible(false);
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            console.error("Update error:", error);
            toast.error("Failed to update user.");
        }
    };

    const confirm2 = (_id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger bg-red-500 ml-2 text-white py-2 px-3',
            rejectClassName: 'ml-2  py-2 px-3',
            accept: () => { handledelete(_id) }
        });
    };

    const handledelete = async (_id) => {
        try {
            const res = await apideleteShippingAddress(_id);
            if (res.message === 'Address deleted successfully') {
                toast.success(res.message);
                await fetchShippingDetails(); 
    
                if (shippingDetails.length > 1) {
                    setSelectedAddressIndex(0);
                } else {
                    setSelectedAddressIndex(-1);
                }
            } else {
                toast.error(res.message);
            }
        } catch (error) {
            console.log('Error deleting address:', error);
            toast.error('Failed to delete address.');
        }
    };

    
   
    return (
        <div className={`fixed top-0 right-0 lg:w-[25%] md:w-[50%] xl:w-[40%] 2xl:w-[30%] w-full h-full bg-white shadow-lg z-50 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
            <div className="p-4">
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 fill-black" viewBox="0 0 320.591 320.591">
                        <path d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z" />
                        <path d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z" />
                    </svg>
                </button>

                <h2 className="mb-4 text-lg font-bold">Checkout</h2>

                {/* Shipping Details */}
                {shippingDetails.length > 0 ? (
                    <div className="mb-4">
                        <div className="flex items-center justify-between mb-2">
                            <p className="font-medium">{shippingDetails[selectedAddressIndex]?.First_Name} {shippingDetails[selectedAddressIndex]?.Last_Name}</p>
                            <div className="flex space-x-2">
                                <button onClick={() => editform(shippingDetails[selectedAddressIndex])} className="p-1 text-blue-600 transition-colors duration-200 rounded-full hover:bg-blue-100">
                                    <Edit size={16} />
                                </button>
                                <button onClick={() => confirm2(shippingDetails[selectedAddressIndex]._id)}  className="p-1 text-red-600 transition-colors duration-200 rounded-full hover:bg-red-100">
                                    <Trash2 size={16} />
                                </button>
                            </div>
                        </div>
                        <p className="text-sm">{shippingDetails[selectedAddressIndex]?.Address}</p>
                        <p className="text-sm">{shippingDetails[selectedAddressIndex]?.Street_Address}</p>
                        <p className="text-sm">{shippingDetails[selectedAddressIndex]?.City}, {shippingDetails[selectedAddressIndex]?.State}, {shippingDetails[selectedAddressIndex]?.Zipcode}</p>

                        <select
                            value={selectedAddressIndex}
                            onChange={(e) => setSelectedAddressIndex(Number(e.target.value))}
                            className="w-full p-2 mt-2 border rounded"
                        >
                            {shippingDetails.map((address, index) => (
                                <option key={address._id} value={index}>
                                    {address.Street_Address}, {address.City}
                                </option>
                            ))}
                        </select>
                    </div>
                ) : (
                    <p>Loading shipping details...</p>
                )}

                {/* Cart Items */}
                <div className="space-y-4">
                    {safeCart.length > 0 ? (
                        safeCart.map((item) => {
                            const isComboBook = item.productId?.Book_Name?.includes('Medium Books');
                            const displayQuantity = isComboBook 
                                ? `${item.Quantity} combo${item.Quantity > 1 ? 's' : ''} (${item.Quantity * 3} books)`
                                : item.Quantity;
                            
                            return (
                                <div key={item._id} className="flex items-center justify-between pb-4 border-b">
                                    <img
                                        src={`${apiurl()}/${item?.productId?.Book_image?.[0] || 'default_image_path'}`}
                                        alt={item?.productId?.Book_Name || 'Default Book Name'}
                                        className="object-cover w-16 h-16 rounded-md"
                                    />
                                    <div className="flex-1 ml-4">
                                        <p className="font-medium">{item.productId?.Book_Name}</p>
                                        <p className="text-sm">Quantity: {displayQuantity}</p>
                                    </div>
                                    <p className="font-semibold text-gray-600">₹{item.productId?.Sale_Price}</p>
                                </div>
                            );
                        })
                    ) : (
                        <p>No items in cart</p>
                    )}
                </div>


               {/* Price Summary */}
               <div className="mt-6">
                    <div className="flex justify-between">
                        <p className="font-medium">Price</p>
                        <p>₹{calculateTotal().productTotal.toFixed(2)}</p>
                    </div>
                    <div className="flex justify-between">
                        <div className="flex flex-col">
                            <p className="font-medium">Delivery Charges</p>
                            {calculateTotal().bookTypeCharges.TNPSC > 0 && (
                                <p className="text-sm text-gray-600">TNPSC Books: ₹{calculateTotal().bookTypeCharges.TNPSC.toFixed(2)}</p>
                            )}
                            {calculateTotal().bookTypeCharges['SRB/DRB (Coop)'] > 0 && (
                                <p className="text-sm text-gray-600">SRB/DRB (Coop) Books: ₹{calculateTotal().bookTypeCharges['SRB/DRB (Coop)'].toFixed(2)}</p>
                            )}
                        </div>
                        <p className="text-green-600">₹{calculateTotal().shippingCharges.toFixed(2)}</p>
                    </div>
                    <div className="flex justify-between mt-4 font-semibold">
                        <p>Total</p>
                        <p>₹{calculateTotal().grandTotal.toFixed(2)}</p>
                    </div>
                </div>

                <div className="grid grid-cols-2 gap-5 mt-6">
                    <button onClick={openform} className="px-4 py-2 text-white bg-yellow-500 rounded">Add Shipping</button>
                    <button 
                onClick={() => {
                    if (selectedAddressIndex === '') {
                        toast.error('Please choose an address before placing the order.');
                    } else {
                        const { grandTotal } = calculateTotal();
                        initializeRazorpayPayment(shippingDetails[selectedAddressIndex], grandTotal);
                    }
                }} 
                className="px-4 py-2 text-white bg-orange-500 rounded"
            >
                Pay Now
            </button>
                    {/* <button onClick={() => {
                                if (selectedAddressIndex === '') { toast.error('Please choose an address before placing the order.');} 
                                else {placeOrder(shippingDetails[selectedAddressIndex]); } }} className="px-4 py-2 text-white bg-orange-500 rounded">Place Order
                    </button> */}
                </div>
            </div>
            <ConfirmDialog />
            <Addshipping openform={openform} visible={visible} setVisible={setVisible} handleSave={handleSave} handlechange={handlechange}
                loading={loading} formData={formData} handleupdate={handleupdate}  />
        </div>
    );
}