/* eslint-disable react/prop-types */
import { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure } from "@nextui-org/react";
import { Eye, FileText } from 'lucide-react';

export default function Customtable(props) {
  const { data, editform, confirm2, handlepdfview, handleVideoView } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPdfs, setSelectedPdfs] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  const handlePdfClick = (item, pdfs) => {
    setSelectedPdfs(pdfs);
    setCurrentItem(item);
    onOpen();
  };

  const openPdf = (pdfIndex) => {
    handlepdfview(currentItem._id, pdfIndex);
    onClose();
  };

  return (
    <div className="overflow-auto bg-white border rounded-xl h-[400px] xl:h-[500px] 2xl:h-[700px] my-5">
      <table className="min-w-full divide-y divide-gray-200 rounded-xl">
        <thead>
          <tr>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">S.no</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Subject Title</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Batch</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Course Name</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Course Pdf</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Course Video</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Status</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Action</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data?.resdata?.map((item, index) => (
            <tr key={index}>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{index + 1}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Course_Title}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Batch}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Course_Name}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                {item.Course_Pdf && item.Course_Pdf.length > 0 && (
                  <div className="flex items-center gap-2">
                    <Eye 
                      className="text-blue-500 cursor-pointer hover:text-blue-700" 
                      onClick={() => handlePdfClick(item, item.Course_Pdf)}
                    />
                    <span className="text-sm text-gray-500">({item.Course_Pdf.length})</span>
                  </div>
                )}
              </td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                <span className="flex gap-1 cursor-pointer" onClick={async () => await handleVideoView(item.Course_Video)}> 
                  <i className="text-xl text-red-500 fi fi-brands-youtube"></i> 
                  <span className="">Play</span>
                </span>
              </td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Status}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                <Dropdown>
                  <DropdownTrigger>
                    <i className="text-lg cursor-pointer fi fi-rr-menu-dots-vertical"></i>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Dynamic Actions">
                    <DropdownItem onPress={() => editform(item)} color={"default"}>
                      Edit
                    </DropdownItem>
                    <DropdownItem onPress={() => confirm2(item._id)} color={"danger"} className={"text-danger"}>
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* PDF Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">PDF Documents</ModalHeader>
              <ModalBody>
                <div className="grid grid-cols-2 gap-4 md:grid-cols-6">
                  {selectedPdfs.map((pdf, pdfIndex) => (
                    <div 
                      key={pdfIndex} 
                      className="flex flex-col items-center justify-center p-4 transition-all bg-gray-100 rounded-lg cursor-pointer hover:bg-gray-200"
                      onClick={() => openPdf(pdfIndex)}
                    >
                      <div className="flex flex-col items-center gap-2">
                        <FileText  className="w-12 h-12 text-red-500" />
                        <span className="text-sm font-medium">PDF {pdfIndex + 1}</span>
                      </div>
                      <div className="mt-2">
                        <Eye className="w-6 h-6 text-blue-500 hover:text-blue-700" />
                      </div>
                    </div>
                  ))}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
}