import { apiCreateOrder, apiPaymentDone } from '../../services/apiorder/apiorder';
import { deleteAllcartItems, deletecartItem } from '../../services/apicart/apicart';
import toast from 'react-hot-toast';

export const usePaymentHandlers = (cart, userdetails, clearCart, setCartItems, onClose) => {
    const initializeRazorpayPayment = async (selectedAddress,total) => {
        try {
            if (!selectedAddress) {
                toast.error('Please select a shipping address');
                return;
            }

            if (!total || total <= 0) {
                toast.error('Invalid order amount');
                return;
            }

            const orderData = {
                Total_Amount: total,
                Billing_Name: `${selectedAddress.First_Name} ${selectedAddress.Last_Name}`.trim(),
                Email: userdetails?.Email,
                Mobilenumber: selectedAddress.Mobilenumber,
                Delivery_Address: `${selectedAddress.Address}, ${selectedAddress.City} - ${selectedAddress.Zipcode}, ${selectedAddress.State}, ${selectedAddress.Country}`.trim(),
                City: selectedAddress.City,
                State: selectedAddress.State,
                Country: selectedAddress.Country,
                Zipcode: selectedAddress.Zipcode,
                Delivery_Address_id: selectedAddress._id,
                Payment_Status: "Pending",
                Order_Status: "Payment Pending",
                currency: "INR"
            };

            const orderResponse = await apiCreateOrder(orderData);
            
            if (!orderResponse?.data?.id) {
                toast.error('Failed to create order. Please try again.');
                return;
            }

            let paymentAttempted = false;

            const options = {
                key: "rzp_live_j19PFR7ZooScsu",
                // key: "rzp_test_1zkqRkO3wEqE4u",
                amount: orderResponse.data.amount,
                currency: orderResponse.data.currency,
                name: "Kavins Academy",
                description: "Purchase Payment",
                order_id: orderResponse.data.id,
                handler: async function(response) {
                    paymentAttempted = true;
                    await handlePaymentSuccess(response, orderData, orderResponse);
                },
                modal: {
                    ondismiss: async function() {
                        if (!paymentAttempted) {
                            await handlePaymentFailure(orderData, orderResponse, 'Payment cancelled by user');
                        }
                    }
                },
                prefill: {
                    name: `${selectedAddress.First_Name} ${selectedAddress.Last_Name}`.trim(),
                    email: userdetails?.Email,
                    contact: selectedAddress.Mobilenumber
                },
                theme: {
                    color: "#292665"
                },
            };

            const razorpay = new window.Razorpay(options);
            
            razorpay.on('payment.failed', async function(response) {
                paymentAttempted = true;
                await handlePaymentFailure(orderData, orderResponse, response.error.description);
            });

            razorpay.open();

        } catch (error) {
            console.error('Payment initialization error:', error);
            toast.error(error.response?.data?.error || 'Failed to initialize payment');
        }
    };

    const handlePaymentSuccess = async (response, orderData, orderResponse) => {
        try {
            const orderItems = cart.map(item => ({
                First_Name: orderData.First_Name,
                Book_Name: item.productId.Book_Name,
                Book_image: item.productId.Book_image,
                Regular_Price: item.productId.Regular_Price,
                Discount: item.productId.Discount,
                Sale_Price: item.productId.Sale_Price,
                Quantity: item.Quantity
            }));

            const paymentData = {
                orderdata: {
                    ...orderData,
                    Order_id: orderResponse.data.receipt,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_signature: response.razorpay_signature,
                    Payment_Status: "Paid",
                    Order_Status: "Confirmed"
                },
                ordermasterdata: orderItems
            };

            const saveResponse = await apiPaymentDone(paymentData);

            if (saveResponse.message === "Order saved successfully" || 
                saveResponse.message === "Order updated successfully") {
                toast.success('Payment successful and order placed!');
                await deletecartItem();
                await deleteAllcartItems();
                clearCart();
                setCartItems([]);
                onClose();
            }
        } catch (error) {
            console.error('Error saving successful order:', error);
            toast.error('Payment successful but order saving failed');
        }
    };

    const handlePaymentFailure = async (orderData, orderResponse, errorMessage) => {
        try {
            const orderItems = cart.map(item => ({
                First_Name: orderData.First_Name,
                Book_Name: item.productId.Book_Name,
                Book_image: item.productId.Book_image,
                Regular_Price: item.productId.Regular_Price,
                Discount: item.productId.Discount,
                Sale_Price: item.productId.Sale_Price,
                Quantity: item.Quantity
            }));

            const paymentData = {
                orderdata: {
                    ...orderData,
                    Order_id: orderResponse.data.receipt,
                    Payment_Status: "Failed",
                    Order_Status: "Payment Failed",
                    failed_reason: errorMessage
                },
                ordermasterdata: orderItems
            };

            await apiPaymentDone(paymentData);
            toast.error(`Payment failed: ${errorMessage}`);
        } catch (error) {
            console.error('Error saving failed order:', error);
            toast.error('Failed to save order details');
        }
    };

    return {
        initializeRazorpayPayment
    };
};