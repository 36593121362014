/* eslint-disable react/prop-types */
import { useCallback, useEffect, useState } from "react";
import Swiper from "swiper";
import 'swiper/css';
import { apigetallbooksdata } from "../../services/apigetbooks/apigetbooks";
import apiurl from "../../../admin/shared/services/apiendpoint/apiendpoint";
import useCartStore from "../../services/store/usecart";
import toast from 'react-hot-toast'
import { apisavecart } from "../../services/apicart/apicart";
import useAuth from "../../services/store/useAuth";
import { Tooltip } from "@nextui-org/react";
import Cartsidebar from "../CheckoutandCart/Cartsidebar";
import { Link } from "react-router-dom";

const BookImageSkeleton = () => (
    <div className="animate-pulse">
        <div className="w-full h-64 bg-gray-300 rounded-t-md"></div>
    </div>
);

export default function Studymaterials() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const { userdetails } = useAuth();
    const [books, setBooks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {addToCart, cart: cartItems, cart} = useCartStore();
    let isMounted = true;

    const fetchbooks = useCallback(() => {
        const fetchBooks = async () => {
            try {
                const data = await apigetallbooksdata({});
                setBooks(data.resdata || []);
                setIsLoading(false);
            } catch (error) {
                console.error("Failed to fetch books:", error);
                setIsLoading(false);
            }
        };
        fetchBooks();
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetchbooks();
        }
        return (() => isMounted = false);
    }, []);

    useEffect(() => {
        if (books.length > 0) {
            new Swiper(".bookslider", {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: true,
                autoplay: true,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            });
        }
    }, [books]);

    const handleAddToCart = async (book) => {
        const userDetails = userdetails;
        if (!userDetails) {
            toast.error("Please log in to add items to your cart!");
            return;
        }

        if (userDetails.Role === "Student") {
            toast.error("Students cannot make purchases. Please login with a customer account.");
            return;
        }

        // Check if item is out of stock
        if (book.Quantity === 0) {
            toast.error("This item is currently out of stock!");
            return;
        }
        const cartItemsFromStore = cartItems || [];
        if (cartItemsFromStore.some(item => item.productId._id === book._id)) {
            toast.error("Product is already in your cart!");
            return;
        }
        try {
            const cartData = { productId: book._id, Email: userDetails.Email, Quantity: 1 };
            await apisavecart(cartData);

            addToCart(book);
            toast.success("Product added to cart successfully!");
    
        } catch (error) {
            toast.error("Failed to add product to cart.");
            console.error("Error adding product to cart:", error);
        }
    };

    const getStockStatus = (quantity) => {
        if (quantity === undefined || quantity === null) {
            return { text: "In Stock", color: "bg-green-100 text-green-800" };
        }
        if (quantity === 0) {
            return { text: "Out of Stock", color: "bg-red-100 text-red-800" };
        }
        if (quantity < 10) {
            return { text: "Low Stock", color: "bg-yellow-100 text-yellow-800" };
        }
        return { text: "In Stock", color: "bg-green-100 text-green-800" };
    };

    const toggleSidebar = () => { setIsSidebarOpen(!isSidebarOpen); };
    
    return (
        <>
            <section className="bg-[url('/images/about/bg-img.jpg')] bg-center bg-no-repeat overflow-hidden">
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-17 mx-auto">
                    <div className="grid grid-cols-1 gap-6 text-center md:gap-6">
                        <div>
                            <h1 className="mb-3 text-3xl font-extrabold text-white lg:text-5xl">Study Materials</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-12 mx-auto">
                    <div className="grid grid-cols-2 gap-6 md:grid-cols-3 lg:grid-cols-5 md:gap-6">
                        {isLoading 
                            ? Array(10).fill().map((_, index) => (
                                <div className="border-2 rounded-md animate-pulse" key={index}>
                                    <div className="flex justify-between p-2">
                                        <div className="h-6 bg-gray-300 rounded-full w-14"></div>
                                        <div className="w-20 h-6 bg-gray-300 rounded-full"></div>
                                    </div>
                                    <div className="p-5 rounded-xl">
                                        <BookImageSkeleton />
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <div className="w-24 h-6 mx-5 bg-gray-300 rounded"></div>
                                    </div>
                                    <div className="grid grid-cols-1 text-white">
                                        <div className="p-3 text-center bg-gray-300">
                                            <div className="w-full h-6 bg-gray-400 rounded"></div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : books.map((book) => {
                                const stockStatus = getStockStatus(book.Quantity);
                                return (
                                    <div className="border-2 rounded-md" key={book._id}>
                                    <div className="flex justify-between p-2">
                                        <h1 className="px-2 mx-3 text-white bg-green-600 rounded-full w-fit">
                                            {book.Discount}%
                                        </h1>
                                        <span className={`px-3 py-1 text-xs font-medium rounded-full ${stockStatus.color}`}>
                                            {stockStatus.text}
                                        </span>
                                    </div>
                                    <div className="p-5 rounded-xl">
                                        <div className="w-full h-full swiper bookslider">
                                            <div className="swiper-wrapper">
                                                {book.Book_image.map((imgSrc, index) => (
                                                    <div className="swiper-slide" key={index}>
                                                         <Link to={`/view-product/${book._id}`} state={{ product: book }}>
                                                            <img src={`${apiurl()}/${imgSrc}`} alt={`Cover of ${book.Book_Name}`} className="object-cover w-full h-64 rounded-t-md" />
                                                        </Link>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="swiper-button-next"></div>
                                            <div className="swiper-button-prev"></div>
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <h1 className="relative mx-5 text-lg font-bold lg:text-xl -top-2">
                                            ₹ {book.Sale_Price} 
                                            <span className="mx-1 text-base font-normal">
                                                <s>₹ {book.Regular_Price}</s>
                                            </span>
                                        </h1>
                                    </div>
                                    <div className="grid grid-cols-1 text-white">
                                        <div className="p-3 text-center text-black bg-regal-yellow">
                                            <button onClick={() => handleAddToCart(book)} className={`font-semibold ${book.Quantity === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                                disabled={book.Quantity === 0}
                                            >
                                                {book.Quantity === 0 ? 'Out of Stock' : 'Add To Cart'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                );
                            })
                        }
                    </div>
                </div>
            </section>

            <div className="fixed z-50 flex flex-col items-end space-y-8 bottom-4 right-4">
                <div className="relative" onClick={toggleSidebar}>
                    <img src="/images/shopping-cart.png" alt="Cart" className="cursor-pointer w-14 h-14" />
                    <span className="absolute flex items-center justify-center w-6 h-6 text-xs text-white bg-red-600 rounded-full -top-2 -right-2">
                        {cart.length}
                    </span>
                </div>

                <a href="https://wa.me/9047127777?text=Hello%20I%20want%20to%20enquire%20about%20your%20services" target="_blank" rel="noopener noreferrer">
                    <Tooltip color="success" content="Contact on WhatsApp Now!" placement="left" showArrow={true}>
                        <img src="/images/whatsapp.png" alt="WhatsApp" className="w-14 h-14 animate-bounce-up-down" />
                    </Tooltip>
                </a>
            </div>
            <Cartsidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
        </>
    );
}