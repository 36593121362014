import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import Cuspagination from "../shared/components/Courses/Cuspagination";
import Studenttable from "../shared/components/Students/StudentTable";
import TableHeadpanel from "../shared/components/Students/TableHeadPanel";
import { apideletestudapplication, apigetverifiedapplications, apisendcredentials, apiupdateaddedcourses, apiupdatestudapplication } from "../shared/services/apiapplicationreceived/apireceivedapplications";
import useTable from "../hooks/useTable";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { apigetallclassforadd } from "../shared/services/courses/apicourses";
import AddandEditform from "../shared/components/Students/AddandEditForm";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export default function StudentsPage() {
    const { page, first, rows, globalFilter, setGlobalFilter, onPage } = useTable();
    const [loadingEmail, setLoadingEmail] = useState(null);
    const [courses, setCourses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [selectedBatches, setSelectedBatches] = useState([]);
    const [formdata,setformdata]=useState([]);
    const [visible, setVisible] = useState(false);
    const queryClient = useQueryClient();

    const { data } = useQuery({
        queryKey: ['studentdata', page, rows, globalFilter],
        queryFn: () => apigetverifiedapplications({ first, rows, globalFilter })
    });

    const { data: coursesData } = useQuery({
        queryKey: ['courses'],
        queryFn: () => apigetallclassforadd({})
    });

    const updateCoursesMutation = useMutation({
        mutationFn: ({ id, Added_Courses, Added_Batches }) => apiupdateaddedcourses(id, { Added_Courses, Added_Batches }),
        onSuccess: () => {
            queryClient.invalidateQueries(['studentdata']);
            toast.success('Courses and batch updated successfully');
        },
        onError: (error) => {
            console.error('Error updating courses and batch:', error);
            toast.error('Failed to update courses and batch');
        }
    });

    useEffect(() => {
        if (coursesData) {
            // Extract unique courses and batches
            const uniqueCourses = [...new Set(coursesData.resdata.map(item => item.Course_Name))];
            const uniqueBatches = [...new Set(coursesData.resdata.map(item => item.Batch))];
            
            // Transform into format for MultiSelect
            setCourses(uniqueCourses.map(course => ({ label: course, value: course })));
            setBatches(uniqueBatches.map(batch => ({ label: batch, value: batch })));
        }
    }, [coursesData]);

    useEffect(() => {
        if (data?.resdata) {
            const initialSelectedCourses = {};
            const initialSelectedBatches = {};
            data.resdata.forEach((student, index) => {
                initialSelectedCourses[index] = student.Added_Courses || [];
                initialSelectedBatches[index] = student.Added_Batches || [];
            });
            setSelectedCourses(initialSelectedCourses);
            setSelectedBatches(initialSelectedBatches);
        }
    }, [data?.resdata]);

    const sendEmail = async (Email, UserName, password, Mobile_Number) => {
        setLoadingEmail(Email);
        try {
            const res = await apisendcredentials({ Email, UserName, password, Mobile_Number });
            toast.success(res.message);
            setLoadingEmail(null);
        } catch (error) {
            console.error('Error sending email:', error);
            toast.error('Failed to send email');
        }
    };

    const handleCourseChange = (e, index) => {
        setSelectedCourses(prevState => ({...prevState, [index]: e.value}));
    };

    const handleBatchChange = (e, index) => {
        setSelectedBatches(prevState => ({...prevState, [index]: e.value}));
    };

    const handleSaveCourses = (index) => {
        const courses = selectedCourses[index] || [];
        const batches = selectedBatches[index] || [];
        if (data.resdata[index]?._id) {
            updateCoursesMutation.mutate({ 
                id: data.resdata[index]._id, 
                Added_Courses: courses,
                Added_Batches: batches 
            });
        } else {
            console.error('Invalid ID:', data.resdata[index]?.id);
            toast.error('Invalid ID, cannot update courses');
        }
    };

    const editform=(data)=>{
        setformdata({});
        setformdata(data);
        setVisible(true);
    }
    const handlechange = (e) => {
        if (e.target.files) {
            console.log('File selected:', e.target.files[0]);
            setformdata({...formdata, [e.target.name]: e.target.files[0]});
        } else {
            setformdata({...formdata, [e.target.name]: e.target.value});
        }
    };
   
    const handleupdate = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        
        // Append all existing fields
        Object.keys(formdata).forEach(key => {
            if (formdata[key] !== null && formdata[key] !== undefined) {
                formData.append(key, formdata[key]);
            }
        });
    
        try {
            const res = await apiupdatestudapplication(formdata._id, formData);
            
            if (res.data.message === "Successfully updated") {
                toast.success("Student details updated successfully.");
                queryClient.invalidateQueries(['studentdata']);
                setVisible(false);
            } else {
                toast.error("Failed to update details. Please try again.");
            }
        } catch (err) {
            console.error("Update Error:", err);
            toast.error(err.response?.data?.message || "Failed to update application");
        }
    };

    const handledelete = async (_id) => {
        const res = await apideletestudapplication(_id);
        if (res.message === "Successfully deleted") {
            toast.success("Student details deleted successfully.");

        } else {
            toast.error("Failed to delete details. Please try again.");
        }
    }

    const confirm2 = (_id) => {
        confirmDialog({
            message: 'Do you want to delete this record?',
            header: 'Delete Confirmation',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger bg-red-500 ml-2 text-white py-2 px-3',
            rejectClassName: 'ml-2  py-2 px-3',
            accept: () => { handledelete(_id) }
        });
    };

    return (
        <>
            <TableHeadpanel setGlobalFilter={setGlobalFilter} />
            <Studenttable 
                data={data} 
                sendEmail={sendEmail} 
                loadingEmail={loadingEmail} 
                courses={courses} 
                batches={batches}
                handleCourseChange={handleCourseChange} 
                handleSaveCourses={handleSaveCourses} 
                handleBatchChange={handleBatchChange}
                selectedCourses={selectedCourses}
                selectedBatches={selectedBatches}
                editform={editform}
                confirm2={confirm2}
            />
            {data?.totallength > 0 && (
                <Cuspagination
                    page={page}
                    rows={rows}
                    onPage={onPage}
                    totallength={data?.totallength}
                />
            )}
            <AddandEditform visible={visible} setVisible={setVisible} formdata={formdata} handleupdate={handleupdate} handlechange={handlechange} />
            <ConfirmDialog />
        </>
    );
}