/* eslint-disable react/prop-types */
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import apiurl from "../../services/apiendpoint/apiendpoint";

export default function CustomTable(props) {
  const {confirm2, data,editform} = props;

  return (
    <div className="overflow-auto bg-white border rounded-xl h-[400px] xl:h-[500px] 2xl:h-[700px] my-5">
      <table className="min-w-full divide-y divide-gray-200 rounded-xl">
        <thead>
          <tr>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">S.no</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Book Name</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Book Type</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Book Image</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Quantity</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Regular Price</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Discount</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Delivery Charge</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Sale Price</th>
            <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Action</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {data?.resdata?.map((item, index) => (
            <tr key={index}>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{index + 1}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Book_Name}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Book_Type}</td>

              <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                {item.Book_image && (
                  <img 
                    src={`${apiurl()}/${item.Book_image[0]}`}
                    alt={`Cover of ${item.Book_Name}`} 
                    className="object-cover rounded w-28 h-28"
                  />
                )}
              </td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Quantity}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Regular_Price}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Discount}%</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Delivery_charge}</td>
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Sale_Price}</td>              
              <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                <Dropdown>
                  <DropdownTrigger>
                    <i className="text-lg cursor-pointer fi fi-rr-menu-dots-vertical"></i>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Dynamic Actions">
                    <DropdownItem onPress={() => editform(item)} color={"default"}>
                      Edit
                    </DropdownItem>
                    <DropdownItem onPress={() => confirm2(item._id)} color={"danger"} className={"text-danger"}>
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}