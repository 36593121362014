import { useCallback, useEffect, useState } from "react";
import useAuth from "../../../../../shared/services/store/useAuth";
import { apigetallstuddashcourse } from "../../../services/apistudentdashboard/apistudentDash";
import apiurl from "../../../services/apiendpoint/apiendpoint";
import { BookOpen, User, ClipboardList } from "lucide-react";

export default function Studenthome() {
    const { userdetails } = useAuth();
    const [studentData, setStudentData] = useState(null);
    let isMounted = true;

    const fetchStudentData = useCallback(async () => {
        if (userdetails?.Role === "Student") {
            try {
                const data = await apigetallstuddashcourse({ studentId: userdetails._id });
                setStudentData(data);
            } catch (err) {
                console.error("Failed to fetch student data", err);
            }
        }
    }, [userdetails]);

    useEffect(() => {
        if (isMounted) { fetchStudentData(); }
        return () => { isMounted = false; };
    }, [fetchStudentData]);

    if (!userdetails) {
        return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
    }

    const displayName = userdetails.Role === "Admin" ? "Admin" : (studentData?.studentData?.Full_name || userdetails.Full_name);

    return (
        <div className="min-h-screen p-6 bg-gray-50">
            <div className="mx-auto space-y-6 max-w-8xl">
                {/* Welcome Section */}
                <div className="overflow-hidden rounded-lg shadow-md bg-gradient-to-r from-yellow-500 to-yellow-600">
                    <div className="p-6">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center space-x-4">
                            <div className="p-3 rounded-lg bg-[#1A1F37]">
                                <User className="w-8 h-8 text-white" />
                            </div>
                            <div>
                                <h1 className="text-2xl font-bold text-white">Welcome {displayName}</h1>
                                {userdetails.Role === "Student" && studentData?.studentData?.Status && (
                                    <p className="text-black">Status: {studentData.studentData.Status}</p>
                                )}
                            </div>
                            </div>
                            <div className='flex items-center justify-center'>
                            <img src={studentData?.studentData?.ProfileImage ? `${apiurl()}/${studentData?.studentData?.ProfileImage}` : '/images/dummy-image.jpg'} 
                                alt="Profile" className="w-24 h-24 rounded-full" />

                            </div>
                        </div>
                    </div>
                </div>

                {/* Courses Section */}
                {userdetails.Role === "Student" && studentData && (
                    <div className="overflow-hidden bg-white rounded-lg shadow-md">
                        <div className="p-6 border-b border-gray-200">
                            <div className="flex items-center space-x-2">
                                <BookOpen className="w-5 h-5 text-blue-500" />
                                <h2 className="text-xl font-semibold text-gray-900">Your Courses</h2>
                            </div>
                        </div>
                        <div className="p-6">
                            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                                {studentData.coursesData?.length > 0 ? (
                                    studentData.coursesData.map((course, index) => (
                                        <div 
                                            key={index} 
                                            className="p-4 transition-shadow duration-200 bg-white border border-gray-200 rounded-lg hover:shadow-lg"
                                        >
                                            <div className="flex items-start space-x-3">
                                                <div className="p-2 bg-blue-100 rounded-lg">
                                                    <ClipboardList className="w-5 h-5 text-blue-600" />
                                                </div>
                                                <div>
                                                    <h3 className="font-medium text-gray-900">{course.Course_Title}</h3>
                                                    <p className="text-sm text-gray-500">{course.Course_Name}</p>
                                                    <p className="mt-1 text-xs text-gray-400">Batch: {course.Batch}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="p-4 text-center text-gray-500 rounded-lg col-span-full bg-gray-50">
                                        No courses found
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}