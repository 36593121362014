/* eslint-disable react/prop-types */
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";

export default function ClassTable (props) {
  const {data,editform,confirm2}=props;
    return(
        <div className=" bg-white border rounded-xl h-[400px] xl:h-[500px] 2xl:h-[700px] my-5 overflow-auto">
        <table className="min-w-full divide-y divide-gray-200 rounded-xl">
          <thead >
            <tr>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">S.No</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">ClassType</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Year</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Date</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Course Title</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Program Name</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Description</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Status</th>
              <th scope="col" className="px-6 py-5 text-sm font-medium text-start">Action</th>

            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
          {data?.resdata?.map((item,index)=>
              <tr key={index}>
                <td scope="col" className="px-6 py-5 text-sm text-gray-500">{index+1}</td>
                <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.ClassType}</td>
                <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Year}</td>
                <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Date}</td>
                <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Couse_Title}</td>
                <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Program_Name}</td>
                <td className="px-6 py-5 text-sm text-gray-500">
                <div dangerouslySetInnerHTML={{ __html: item.Description }} />
                </td>
                <td scope="col" className="px-6 py-5 text-sm text-gray-500">{item.Status}</td>
                <td scope="col" className="px-6 py-5 text-sm text-gray-500">
                  <Dropdown>
                    <DropdownTrigger>
                      <i className="text-lg cursor-pointer fi fi-rr-menu-dots-vertical"></i>
                    </DropdownTrigger>
                    <DropdownMenu aria-label="Dynamic Actions" >
                      <DropdownItem onPress={()=>editform(item)} color={"default"}>
                        Edit
                      </DropdownItem>
                      <DropdownItem onPress={()=>confirm2(item._id)} color={"danger"} className={"text-danger"}>
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </td>
              </tr>
          )}
          </tbody>
        </table>
      </div>
    )
}